@font-face {
  font-family: "Cairo";
  src: local("Cairo"),
    url("./assets/fonts/Cairo-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Cairo";
}
::selection {
  background-color: #f7d694;
  color: #0e0d0d;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: "#3A6CB3";
  transition: background-color 0.2s ease-in-out;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: "#3A6CB3";
}

.spinner-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn {
  box-shadow: none !important ;
}

.ant-modal-content {
  padding: 0px !important;
}

.ant-otp-input {
  font-size: 2rem !important;
  font-weight: bold !important;
  color: #3a6cb3 !important;
}

.ant-table-cell {
  align-content: start !important;
}
.ant-table-cell::before {
  background-color: rgb(192, 189, 189) !important;
}
.ql-emoji {
  background-image: url("./assets/images/relaxed-emoji.png") !important;
  background-size: contain  !important;
  background-repeat: no-repeat !important;
  margin: 0px -20px  !important;
}
